import React from 'react'
import placeholder from "./../img/placeholder.png";
import { Link } from 'gatsby';

const listContact = ( props ) => {
    return (
        <div class="px-10 py-4 flex items-center laptop:pt-5">
            <img src={placeholder} class="w-16"></img>
            <div class="w-1/3">
                <h5 class="text text-md font-bold px-10 w-10/12 text-left laptop:text-xl">{props.name}</h5>
                <Link to={props.link}><p class="text text-md px-10 w-10/12 text-left hover:text-yellow transition duration-100 ease-in">{props.userName}</p></Link>
            </div>
        </div>
    )
}

export default listContact
