import React from 'react'
import Nav from "../component/nav"
import ListContact from '../component/listContact';
import FadeIn from 'react-fade-in';

const contact = () => {
    return (
        <div class="container mx-auto">
            <Nav />
            <FadeIn>
                <h1 class="text text-4xl font-bold px-10 py-7 text-left laptop:text-5xl">Contact Me</h1>
                <div class="laptop:flex">
                    <div>
                        <ListContact name="Email" userName="alexkem132@gmail.com" link=""/>
                        <ListContact name="Github" userName="github.com/kzmbo" link="https://github.com/kzmbo"/>
                        <ListContact name="LinkedIn" userName="linkedin.com/in/alex-kem" link="https://www.linkedin.com/in/alex-kem-ab8412237/"/>
                    </div>
                    <div class="px-10 w-full mb-10">
                        <h5 class="text text-xl font-bold py-2">Name</h5>
                        <input type="text" class="w-full bg-gray-dark p-1 text-gray-lightest shadow-md focus:outline-none focus:shadow-outline"></input>
                        
                        <h5 class="text text-xl font-bold py-2">Email</h5>
                        <input type="text" class="w-full bg-gray-dark p-1 text-gray-lightest shadow-md focus:outline-none focus:shadow-outline"></input>
                        
                        <h5 class="text text-xl font-bold py-2">Description</h5>
                        <textarea class="w-full h-64 bg-gray-dark p-3 text-gray-lightest shadow-md focus:outline-none focus:shadow-outline"></textarea>
                        <button class="btn">Submit!</button>
                    </div>
                </div>
            </FadeIn>
        </div>
    )
}

export default contact
